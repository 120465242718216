import * as React from "react";
import styled from "styled-components";
import { Paragraph, Paragraphs, Text } from "../../../atoms/typography";
import { Theme } from "../../../core/app.theme";
import { typography } from "../../../core/styled-system";
import { Responsive } from "../../../gls/responsive";
import { StretchSpacer } from "../../../gls/spacer";
import { Vertical } from "../../../gls/vertical";

export const InfoPanel = (props: {
  image?: React.ReactNode;
  title: string;
  subtitle?: string;
  description: any[];
  points: string[];
  pointsAlign?: "center" | "top";
}) => (
  <Vertical space={"xl"}>
    <Vertical space={"xs"}>
      <Text fontSize={"2xl"} maxWidth={"500px"} fontWeight={"bold"} lineHeight={"shorter"}>
        {props.title}
      </Text>
      <Text fontSize={"md"} maxWidth={"500px"} lineHeight={"shorter"} fontWeight={"light"} textTransform={"uppercase"}>
        {props.subtitle}
      </Text>
    </Vertical>
    {props.image && props.image}
    <Responsive horizontal={{ verticalAlign: props.pointsAlign || "center" }}>
      <Paragraphs>
        {props.description.map((it, index) => (
          <Paragraph key={index} maxWidth={450}>
            {it}
          </Paragraph>
        ))}
      </Paragraphs>
      <StretchSpacer />
      <InfoList>
        {props.points.map((it, index) => (
          <InfoListItem key={index}>{it}</InfoListItem>
        ))}
      </InfoList>
    </Responsive>
  </Vertical>
);

export const InfoList = styled.ul({
  padding: 0,
  margin: 0,
  listStyle: "none",
  li: {
    paddingLeft: "24px",
    borderLeft: `3px solid ${Theme.color("primary")}`,
  },
});

export const InfoListItem = styled.li((props) => ({
  maxWidth: 550,
  color: Theme.color("primary"),
  ...typography({
    fontSize: "md",
    fontFamily: "monument",
    lineHeight: "shorter",
    ...props,
  }),
  ":not(:last-child)": {
    marginBottom: `${Theme.space("lg")}`,
  },
  [Theme.media("lg")]: {
    fontSize: Theme.fontSize("2xl"),
  },
}));
