import * as React from "react";
import styled from "styled-components";
import { Text } from "../../components/atoms/typography";
import { Theme } from "../../components/core/app.theme";
import { ResponsiveLayout } from "../../components/gls/responsive-layout";
import { VerticalSpacer } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { Section } from "../../components/templates/page/section/section";

export const AboutUsBenefitsSection = () => (
  <Section backgroundColor={"icywaters"}>
    <Text
      fontSize={"lg"}
      textAlign={"left"}
      fontWeight={"book"}
      colorScheme={"ghostgumwhite"}
      textTransform={"uppercase"}
      fontFamily={"monument"}
      maxWidth={700}
    >
      The benefits for both consumers & investors
    </Text>
    <VerticalSpacer space={"xl"} />
    <ResponsiveLayout desktop={() => <DesktopView />} mobile={() => <MobileView />} />
  </Section>
);

const MobileView = () => (
  <div>
    <Vertical>
      <Text fontWeight={"bold"} colorScheme={"white"}>
        Benefits for the consumer
      </Text>
      <Text colorScheme={"white"}> 100% renewable hydrogen, created at the point of electricity generation.</Text>
      <Text colorScheme={"white"}>
        {" "}
        Forecourts vastly different from the traditional. Emphasis on natural setting rather than industrial harshness.
      </Text>
      <Text colorScheme={"white"}>
        {" "}
        Facilities which promote and demonstrate low impact living and re-use of resources.
      </Text>
      <Text colorScheme={"white"}> High quality and well considered amenities and services for customers.</Text>
      <Text colorScheme={"white"}>
        {" "}
        A setting which benefits the community by enhancing its understanding of the future of energy.
      </Text>
      <Text colorScheme={"white"}>
        {" "}
        Mission to foster community cohesion via shared appreciation of environmental issues - building resilient
        communities.
      </Text>
    </Vertical>
    <VerticalSpacer space={"xl"} />
    <Vertical>
      <Text fontWeight={"bold"} colorScheme={"white"}>
        Benefits for the investor
      </Text>
      <Text colorScheme={"white"}>
        {" "}
        Asset based business, across the full value chain from hydrogen creation to its dispensing
      </Text>
      <Text colorScheme={"white"}>
        {" "}
        Modular assets which can be very quickly shifted wherever commercial demands require.
      </Text>
      <Text colorScheme={"white"}> Strong depth of related experience.</Text>
      <Text colorScheme={"white"}>
        {" "}
        Building facilities which will provide more sustainable environmental practices for future generations.
      </Text>
      <Text colorScheme={"white"}> Multiple streams of revenue within the business.</Text>
      <Text colorScheme={"white"}> Leader in off-grid commercial business, at scale.</Text>
    </Vertical>
  </div>
);
const DesktopView = () => (
  <Table>
    <thead>
      <tr>
        <th>Benefits for the consumer</th>
        <th>Benefits for the investor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>100% renewable hydrogen, created at the point of electricity generation.</td>
        <td>Asset based business, across the full value chain from hydrogen creation to its dispensing.</td>
      </tr>
      <tr>
        <td>
          Forecourts vastly different from the traditional. Emphasis on natural setting rather than industrial
          harshness.
        </td>
        <td>Modular assets which can be very quickly shifted wherever commercial demands require.</td>
      </tr>
      <tr>
        <td>Facilities which promote and demonstrate low impact living and re-use of resources.</td>
        <td>Strong depth of related experience.</td>
      </tr>
      <tr>
        <td>High quality and well considered amenities and services for customers.</td>
        <td>Building facilities which will provide more sustainable environmental practices for future generations.</td>
      </tr>
      <tr>
        <td>A setting which benefits the community by enhancing its understanding of the future of energy.</td>
        <td>Multiple streams of revenue within the business.</td>
      </tr>
      <tr>
        <td>
          Mission to foster community cohesion via shared appreciation of environmental issues - building resilient
          communities.
        </td>
        <td>Leader in off-grid commercial business, at scale.</td>
      </tr>
    </tbody>
  </Table>
);

const Table = styled.table({
  borderCollapse: "collapse",
  borderSpacing: "50px",
  width: "100%",
  color: "white",
  th: {
    textAlign: "left",
    textTransform: "uppercase",
    paddingBottom: Theme.space("sm"),
  },
  td: {
    border: "1px solid #F1F1F1",
    paddingTop: Theme.space("sm"),
    paddingBottom: Theme.space("sm"),
    maxWidth: 300,
  },
  "td:nth-child(1)": {
    paddingRight: Theme.space("2xl"),
  },
  "td:nth-child(2)": {
    paddingRight: Theme.space("lg"),
  },
  ["tr:last-child td"]: {
    borderTop: 0,
    borderBottom: 0,
  },
  ["tr td"]: {
    borderLeft: 0,
    borderRight: 0,
  },
});
