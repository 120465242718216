import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
// @ts-ignore
import VideoManAtPump from "../../assets/video/man-at-pump.mp4";
import { Link } from "../../components/atoms/link";
import { Paragraph, Text } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { StretchSpacer, VerticalSpacer } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { InfoPanel } from "../../components/templates/page/panel/info-panel";
import { Section } from "../../components/templates/page/section/section";
import { SectionInfo, SectionInfoQuoteText } from "../../components/templates/page/section/section-info";

export const AboutUsSectionWorldClassTechnology = () => (
  <Section>
    <InfoPanel
      title={"World-class technology"}
      description={[
        "H2FA is partnering with some of the world’s leading suppliers of industrial hydrogen equipment through its work with Skai Energies. H2FA will utilise Nilsson Energy (Sweden) RE8760 technology to manage the site micro-grid, allowing all facilities to operate as an island and be completely self-sufficient.",
        "Contained within the Nilsson Energy solution is critical equipment from:",
        <Link href={"https://greenhydrogen.dk"} target={"_blank"}>
          Green Hydrogen Systems (Denmark), designers and manufacturers of efficient, modular electrolysers to produce
          hydrogen.
        </Link>,
        <Link href={"http://www.idromeccanica.it"} target={"_blank"}>
          Idro Meccanica (Atlas Copco) (Italy), leading manufacturers of compressors.
        </Link>,
      ]}
      points={["100% self-sufficient", "Zero-emission fuel cells", "Clean hydrogen"]}
    />
  </Section>
);

export const AboutUsSectionUniqueDesign = () => (
  <Section>
    <Responsive>
      <Vertical>
        <Text fontSize={"2xl"} maxWidth={"400px"} fontWeight={"bold"} lineHeight={"shorter"}>
          Unique design with the user in mind
        </Text>
        <StretchSpacer />
        <Paragraph maxWidth={450}>
          In addition to housing Victoria’s first integrated modular commercial vehicle and mass transit hydrogen
          refuelling facility, the Truganina site will not only showcase innovative and imaginative design but also
          feature research and educational facilities.
        </Paragraph>
      </Vertical>
      <StretchSpacer />
      <a style={{ textDecoration: "none" }} href={"https://www.youtube.com/watch?v=rOAa-yIsrN8"} target={"_blank"}>
        <StaticImage
          src={`../../assets/images/infrastructure/man-with-dispenser.png`}
          height={550}
          placeholder="blurred"
          alt=""
        />
        <VerticalSpacer space={"sm"} />
        <Text fontSize={"xs"} textAlign={"center"} fontWeight={"light"} fontStyle={"italic"}>
          H_Scaled dispenser as showcased at the Venice Biennale.
        </Text>
      </a>
    </Responsive>
    <VerticalSpacer space={"2xl"} />
    <video
      style={{
        width: "100%",
        objectFit: "cover",
        height: 400,
      }}
      autoPlay={"autoplay" as any}
      loop
      muted
      playsInline
    >
      <source src={VideoManAtPump} type="video/mp4" />
    </video>
  </Section>
);

export const AboutSectionQuoteCraig = () => (
  <SectionInfo>
    <SectionInfoQuoteText
      quote={
        "GHS decided from its inception to focus on improving technology to make it more suitable for today’s demands for hydrogen produced from only renewable energy."
      }
      author={"Craig Ehrke, CEO, Skai Energies"}
    />
  </SectionInfo>
);

export const AboutUsSectionQuoteRobert = () => (
  <SectionInfo>
    <SectionInfoQuoteText
      author={"Robert Grove, CCO, H2FA"}
      quote={
        "The modular H2FA concept will enable the company to expand rapidly across Victoria and Australia to meet growing demand for hydrogen refuelling, while allocating capital and other resources with optimal efficiency."
      }
    />
  </SectionInfo>
);
