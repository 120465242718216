import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
// @ts-ignore
import VideoManAtPump from "../../assets/video/man-at-pump.mp4";
// @ts-ignore
import VideoNilsonEnergy from "../../assets/video/nilson-energy.mp4";
import { VerticalSpacer } from "../../components/gls/spacer";
import { InfoPanel } from "../../components/templates/page/panel/info-panel";
import { Section } from "../../components/templates/page/section/section";

export const AboutUsSectionGreenHydrogen = () => (
  <Section>
    <video
      title={"Nilson energy"}
      style={{
        width: "100%",
        objectFit: "cover",
      }}
      controls
      autoPlay={"autoplay" as any}
      loop
      muted
      playsInline
    >
      <source src={VideoNilsonEnergy} type="video/mp4" />
    </video>
    <StaticImage
      src={`../../assets/images/infrastructure/curved-assembling-equipment.png`}
      placeholder="blurred"
      alt=""
    />
    <VerticalSpacer space={"xl"} />
    <InfoPanel
      title={"Generating green renewable hydrogen"}
      description={[
        "H2FA’s 5-hectare site will initially be capable of producing 60-90kgs of green hydrogen from its own 750kw solar array. Ultimately the site will be scaled up to supply approximately 3,000kg with capacity to fuel 100+ vehicles per day.",
        "Due to the modular nature of H2FA’s hydrogen power facilities, the site will have all equipment above ground, meaning that the operation can be re-located with minimal environmental impact and cost – a major advantage over legacy petroleum and diesel refuelling stations. H2FA’s new, completely self-sufficient operation in Melbourne represents a replicable standard for future stations throughout Australia by taking maximum advantage of its modular concept while also benefiting from a low cost operating model.",
      ]}
      points={["Circular power generation", "Island mode", "Minimal environmental impact"]}
    />
  </Section>
);
