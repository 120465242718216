import * as React from "react";
import { Paragraph, Paragraphs } from "../../components/atoms/typography";
import { Section } from "../../components/templates/page/section/section";
import { SectionIntroHeading, SectionIntroLayout } from "../../components/templates/page/section/section-intro";

export const AboutUsSectionIntro = () => (
  <Section>
    <SectionIntroLayout
      left={<SectionIntroHeading maxWidth={500}>About Hydrogen Fuels Australia</SectionIntroHeading>}
      right={
        <Paragraphs>
          <Paragraph maxWidth={450}>
            Hydrogen Fuels Australia is an industry pioneer in the development of next generation modular hydrogen fuel
            generation with integrated refuelling facilities in Melbourne, Australia. H2FA is launching Australia’s
            first integrated modular hydrogen fuel generation and refuelling operation at its greenfield facility in
            Truganina, near Laverton.
          </Paragraph>
          <Paragraph maxWidth={450}>
            Founded on environmentally sustainable and low impact concepts, H2FA’s operation uses its own electrolysis
            assets (in island mode) to convert renewable power into green hydrogen. H2FA’s facilities will not be
            connected to the energy grid; rather, they will rely on the circular generation of power.
          </Paragraph>
        </Paragraphs>
      }
    />
  </Section>
);
