import * as React from "react";
import {
  PartnerLogoGreenHydrogen,
  PartnerLogoIndro,
  PartnerLogoNilson,
  PartnersHorizontal,
} from "../../components/templates/common/partner-logo";
import { Section } from "../../components/templates/page/section/section";

export const AboutUsSectionPartners = () => (
  <Section backgroundColor={"ghostgumgrey300"} py={"md"}>
    <PartnersHorizontal>
      <PartnerLogoGreenHydrogen />
      <PartnerLogoNilson />
      <PartnerLogoIndro />
    </PartnersHorizontal>
  </Section>
);
